<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register">
              <template v-for="(item, index) in itemsStep">
                <li :key="index" :class="
                  (item.active && 'active') ||
                  (item.finish && 'navtop-canclick')
                " @click="
  item.finish &&
  stepwh_signup < 8 &&
  changeStep(item.linkItems)
">
                  <a href="" class="d-flex flex-column">
                    <span v-if="item.finish" class="icons"><img src="@/assets/icons/icon-checkmark.svg" alt="" /></span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto" ref="basicContainer">
            <div class="form-box">
              <h3 class="title-md">{{ $t("SignupStep1.property_name") }}</h3>

              <div class="form-group">
                <label class="light">{{
                  $t("SignupStep1.property_search")
                }}</label>
                <div class="dropdown select" @click="getfocus('selectaccname')"
                  @mouseleave="mouseOver('selectaccname')">
                  <a data-toggle="dropdown" data-display="static" id="selectaccname">
                    <v-combobox v-model="accommodationData.name" :items="itemsAccName" item-text="name"
                      item-value="name" label="" placeholder="" :no-data-text="$t('SignupStep.nodata')" color="pink"
                      solo flat dense single-line hide-details autocomplete="off" append-icon="" ref="autoselectaccname"
                      @mouseenter="getfocus('selectaccname')" @mouseleave="mouseOver('selectaccname')">
                      <template v-slot:item="data">
                        <v-list-item-content @mouseenter="getfocus('selectaccname')"
                          @mouseleave="mouseOver('selectaccname')" @click="selectAccNameFunc(data.item)">
                          <v-list-item-title>
                            <span>
                              {{ data.item.name }}
                              <span class="font-weight-light" style="
                                  font-size: 0.8rem !important;
                                  color: #555 !important;
                                ">
                                {{
                                `${data.item.location1 + ", " || ""}${data.item.location2 + ", " || ""
  }${data.item.location3 + ", " || ""}${data.item.location4 + ", " || ""
  }${data.item.location5 + ", " || ""}${data.item.location6 + ", " || ""
  }${data.item.location7 + ", " || ""}`
                                }}
                              </span>
                            </span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                  </a>
                </div>
                <span class="error-text-input" v-if="warndataname">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>
              <div class="form-group">
                <label class="light mb-3">{{ $t("SignupStep1.star") }}</label>
                <div class="dropdown select" @click="getfocus('selectstar')" @mouseleave="mouseOver('selectstar')">
                  <a data-toggle="dropdown" data-display="static" id="selectstar">
                    <v-autocomplete v-model="accommodationData.star" :items="itemsStar" item-text="name"
                      item-value="value" label="" :placeholder="$t('SignupStep.select_one')"
                      :no-data-text="$t('SignupStep.nodata')" color="pink" solo flat dense single-line hide-details
                      autocomplete="off" @mouseenter="getfocus('selectstar')" @mouseleave="mouseOver('selectstar')">
                    </v-autocomplete>
                  </a>
                </div>
              </div>
            </div>

            <div class="form-box">
              <h3 class="title-md">{{ $t("SignupStep1.location_name") }}</h3>

              <div class="form-group">
                <label class="light">{{ $t("SignupStep1.location1") }}</label>
                <input id="location1" ref="location1" type="text" class="form-control" name=""
                  :placeholder="$t('SignupStep1.place_location1')" v-model="accommodationData.location1" />
                <span class="error-text-input" v-if="warndatalocation1">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>

              <div class="form-group">
                <label class="light">{{ $t("SignupStep1.location2") }}</label>
                <input type="text" class="form-control" name="" :placeholder="$t('SignupStep1.place_location2')"
                  v-model="accommodationData.location2" />
              </div>

              <div class="form-group">
                <label class="light">{{ $t("SignupStep1.location7") }}</label>
                <div class="dropdown select" @click="getfocus('selectcountry')"
                  @mouseleave="mouseOver('selectcountry')">
                  <a data-toggle="dropdown" data-display="static" id="selectcountry">
                    <v-autocomplete v-model="accommodationData.location7" :items="itemsCountry" item-text="name"
                      item-value="value" label="" placeholder="" :no-data-text="$t('SignupStep.nodata')" color="pink"
                      solo flat dense single-line hide-details autocomplete="off"
                      @mouseenter="getfocus('selectcountry')" @mouseleave="mouseOver('selectcountry')"></v-autocomplete>
                  </a>
                </div>
              </div>

              <div class="form-group">
                <label class="light">{{ $t("SignupStep1.location5") }}</label>
                <div class="dropdown select" @click="getfocus('selectprovince')"
                  @mouseleave="mouseOver('selectprovince')">
                  <a data-toggle="dropdown" data-display="static" id="selectprovince">
                    <v-autocomplete v-model="accommodationData.location5" :items="itemsProvince" item-text="province"
                      item-value="province_code" label="" placeholder="" :no-data-text="$t('SignupStep.nodata')"
                      color="pink" solo flat dense single-line hide-details autocomplete="off"
                      @mouseenter="getfocus('selectprovince')" @mouseleave="mouseOver('selectprovince')"
                      @change="selectProvinceFunc()"></v-autocomplete>
                  </a>
                </div>
              </div>

              <div class="form-group">
                <label class="light">{{ $t("SignupStep1.location3") }}</label>
                <div class="dropdown select" @click="getfocus('selectamphoe')" @mouseleave="mouseOver('selectamphoe')">
                  <a data-toggle="dropdown" data-display="static" id="selectamphoe">
                    <v-autocomplete v-model="accommodationData.location3" :items="itemsAmphoe" item-text="amphoe"
                      item-value="amphoe_code" label="" placeholder="" :no-data-text="$t('SignupStep.nodata')"
                      color="pink" solo flat dense single-line hide-details autocomplete="off"
                      @mouseenter="getfocus('selectamphoe')" @mouseleave="mouseOver('selectamphoe')"></v-autocomplete>
                  </a>
                </div>
              </div>

              <div class="form-group">
                <label class="light">{{ $t("SignupStep1.location4") }}</label>
                <div class="dropdown select" @click="getfocus('selectdistrict')"
                  @mouseleave="mouseOver('selectdistrict')">
                  <a data-toggle="dropdown" data-display="static" id="selectdistrict">
                    <v-autocomplete v-model="accommodationData.location4" :items="itemsDistrict" item-text="district"
                      item-value="district_code" label="" placeholder="" :no-data-text="$t('SignupStep.nodata')"
                      color="pink" solo flat dense single-line hide-details autocomplete="off"
                      @mouseenter="getfocus('selectdistrict')" @mouseleave="mouseOver('selectdistrict')"
                      @click="selectLocation6Func()"></v-autocomplete>
                  </a>
                </div>
              </div>

              <div class="form-group">
                <label class="light">{{ $t("SignupStep1.location6") }}</label>
                <input id="location6" type="text" class="form-control" name="" placeholder=""
                  v-model="accommodationData.location6" />
                <span class="error-text-input" v-if="warndatazipcode">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>
            </div>

            <div class="form-box">
              <h3 class="title-md">{{ $t("SignupStep1.select_address") }}</h3>

              <div class="form-group">
                <div class="form-group has-button" :class="warndatacurrentplace ? 'mb-0' : ''">
                  <gmap-autocomplete v-if="!initPlace || (cansaveArea && clickFocus)" id="setplacenearby"
                    @place_changed="setPlace" class="form-control" :placeholder="$t('SignupStep1.nearby_address')"
                    :disabled="!cansaveArea">
                  </gmap-autocomplete>
                  <v-text-field v-else-if="
                    (!cansaveArea && !clickFocus) ||
                    initPlace ||
                    (newlat && newlng && currentPlaceName)
                  " id="input_placename" v-model="currentPlaceName" label="" class="form-control mr-3" readonly solo
                    flat dense single-line hide-details @click="focusChangePlace()">
                  </v-text-field>
                  <button class="btn btn-searchmap" type="button" :disabled="!cansaveArea" @click="searchArea()">
                    <span class="icons icon-search"></span>
                  </button>
                </div>
                <span class="error-text-input" v-if="warndatacurrentplace">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>

              <div class="form-group">
                <div class="map h-260">
                  <gmap-map :center="center" :zoom="14" class="my-5 map h-260" :options="mapOptions"
                    @click="selectarea">
                    <GmapMarker v-for="(m, index) in markers" :key="index" :position="m.position"
                      :clickable="cansaveArea" :draggable="cansaveArea" />
                  </gmap-map>
                </div>

                <div v-if="cansaveArea" class="buttons d-flex right">
                  <button class="btn btn-outline w-110 btn-cancel-searchmap" type="button" @click="cancelArea()">
                    <span>{{ $t("SignupStep1.btn_cancel") }}</span>
                  </button>
                  <button class="btn w-110 btn-searchmap" type="button" @click="saveArea()">
                    <span>{{ $t("SignupStep1.btn_save") }}</span>
                  </button>
                </div>
                <div v-else-if="!cansaveArea" class="buttons d-flex right">
                  <button class="btn w-110 btn-searchmap" type="button" @click="editArea()">
                    <span>{{ $t("SignupStep1.btn_edit") }}</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="form-box">
              <h3 class="title-md">{{ $t("SignupStep1.address_title") }}</h3>

              <div class="form-group">
                <label class="light">{{
                  $t("SignupStep1.address_contact_name")
                }}</label>
                <input id="contactname" type="text" class="form-control" name="" placeholder=""
                  v-model="accommodationData.contactname" />
                <span class="error-text-input" v-if="warndatacontactname">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>

              <div class="form-group">
                <label class="light">{{
                  $t("SignupStep1.address_phone")
                }}</label>
                <vue-tel-input id="contactphone" name="contactphone" v-model="accommodationData.contactphone"
                  :inputOptions="phoneNumberOptions" placeholder="08 8888 8888" class="form-control"
                  :allCountries="itemsPhoneCountries" @country-changed="changeTelCT1" mode="international"
                  :defaultCountry="defaultLG" validCharactersOnly required></vue-tel-input>
                <div class="d-flex flex-column">
                  <span class="error-text-input" v-if="warndatacontactphone">{{
                    $t("Alert.pleasefilldata")
                  }}</span>
                  <span class="error-text-input" v-if="warndatacontactphonemore">{{
                    $t("Alert.completefilldata")
                  }}</span>
                </div>
              </div>

              <div class="form-group">
                <label class="light">{{
                  $t("SignupStep1.address_reserve_phone")
                }}</label>
                <vue-tel-input id="contactreservephone" name="contactreservephone"
                  v-model="accommodationData.contactreservephone" :inputOptions="reservePhoneOptions"
                  placeholder="08 8888 8888" class="form-control" :allCountries="itemsPhoneCountries"
                  @country-changed="changeTelCT2" mode="international" :defaultCountry="defaultLG" validCharactersOnly
                  required></vue-tel-input>
                <div class="d-flex flex-column">
                  <span class="error-text-input" v-if="warndatacontactreservephone">{{
                    $t("Alert.pleasefilldata")
                  }}</span>
                  <span class="error-text-input" v-if="warndatacontactreservephonemore">{{
                    $t("Alert.completefilldata")
                  }}</span>
                </div>
              </div>
            </div>

            <div class="form-box">
              <h3 class="title-md">
                {{ $t("SignupStep1.pmsorcm_title1") }} <br />{{
                  $t("SignupStep1.pmsorcm_title2")
                }}
              </h3>
              <div class="form-group">
                <div class="radio-group d-block">
                  <input id="pms_no" :value="accommodationData.radios_PMSOrCM == 'not'" name="pms" type="radio"
                    @change="changePMS('not')" :checked="accommodationData.radios_PMSOrCM == 'not'" />
                  <label for="pms_no"><span>{{ $t("SignupStep1.pmsorcm_noneed") }}</span></label>
                </div>
                <div class="radio-group d-block">
                  <input id="pms_yes" :value="accommodationData.radios_PMSOrCM == 'need'" name="pms" type="radio"
                    @change="changePMS('need')" :checked="accommodationData.radios_PMSOrCM == 'need'" />
                  <label for="pms_yes"><span>{{ $t("SignupStep1.pmsorcm_need") }}</span></label>
                </div>

                <div v-if="accommodationData.radios_PMSOrCM == 'need'" class="pms-box">
                  <div class="d-block indent-40 pt-2">
                    <div class="form-group">
                      <div class="checkbox-group d-block">
                        <input id="a1" v-model="accommodationData.cb_havePMS" name="a" type="checkbox"
                          @change="changePMSName(!accommodationData.cb_havePMS)" />
                        <label for="a1"><span>{{ $t("SignupStep1.pms_title") }}</span></label>
                      </div>

                      <label class="light">{{
                        $t("SignupStep1.fillname")
                      }}</label>
                      <input id="pmsname" name="pmsname" type="text" class="form-control" placeholder=""
                        :key="componentKey" :disabled="!accommodationData.cb_havePMS"
                        v-model="accommodationData.PMSName" />
                      <span class="error-text-input" v-if="warndatapmsname">{{
                        $t("Alert.pleasefilldata")
                      }}</span>
                    </div>

                    <div class="form-group">
                      <div class="checkbox-group d-block">
                        <input id="a2" v-model="accommodationData.cb_haveCM" name="a" type="checkbox"
                          @change="changeCMName(!accommodationData.cb_haveCM)" />
                        <label for="a2"><span>{{ $t("SignupStep1.cm_title") }}</span></label>
                      </div>

                      <label class="light">{{
                        $t("SignupStep1.fillname")
                      }}</label>
                      <input id="cmname" name="cmname" type="text" class="form-control" placeholder=""
                        :key="componentKey" :disabled="!accommodationData.cb_haveCM"
                        v-model="accommodationData.CMName" />
                      <span class="error-text-input" v-if="warndatacmname">{{
                        $t("Alert.pleasefilldata")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-buttons d-flex justify-end">
            <div class="buttons pa-0">
              <button type="button" class="btn xs gl-bt hover_btn" style="text-transform: capitalize !important"
                :disabled="!cansavebasic" @click="basicDataFunc(2)">
                <span>{{ $t("SignupStep1.btn_next") }}</span>
              </button>
            </div>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a :href="pathrightphoto" class="img-exemple iphone-model d-flex flex-column align-center justify-center"
              data-fancybox>
              <span class="btn-open-picture">{{
                $t("SignupStep4.clickopenpicture")
              }}</span>
              <img :src="pathrightphoto" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";
import raw from "@/constants/raw.json";
import allcountries from "@/constants/all-countries";
import { gmapApi } from "vue2-google-maps";
import { VueTelInput } from "vue-tel-input";
import roundToNearestMinutesWithOptions from "date-fns/fp/roundToNearestMinutesWithOptions";
import { PlaceApiKey } from "@/API/APIKey.json";

export default {
  name: "BasicScreen",
  metaInfo: {
    title: "Aigent-sign up",
    titleTemplate: "%s | Aigent",
  },
  components: {
    VueTelInput,
  },
  data: () => ({
    stepwh_signup: 1,
    propertyID: null,
    itemsAccName: [],
    itemsPhoneCountries: allcountries,
    itemsStar: [],
    accommodationData: {
      latlng_position: {
        lat: 44.2899,
        lng: 11.8774,
      },
      latlng_title: "",
      latlng_description: "",
      radios_PMSOrCM: null,
      cb_havePMS: false,
      PMSName: "",
      cb_haveCM: false,
      CMName: "",
    },
    phoneNumberOptions: { placeholder: "" },
    reservePhoneOptions: { placeholder: "" },
    itemsCountry: [
      {
        _id: 0,
        name: "ไทย",
        value: "th",
      },
    ],
    itemsProvince: [],
    itemsAmphoe: [],
    itemsDistrict: [],
    center: { lat: 0, lng: 0 },
    markers: [],
    newlat: "",
    newlng: "",
    places: [],
    currentPlace: null,
    currentPlaceName: null,
    value_element: "",
    defaultLG: "",
    pinPhone1: "",
    pinPhone2: "",
    dialCode1: "",
    dialCode2: "",
    cansaveArea: true,
    itemsStep: [],
    pathrightphoto: "",
    mapOptions: {},
    componentKey: 0,
    userToken: "",
    loadingCountry: false,
    loadingProvince: false,
    loadingAmphoe: false,
    loadingDistrict: false,
    loadingZipCode: false,
    cansavebasic: false,
    warndataname: false,
    warndatalocation1: false,
    warndatazipcode: false,
    warndatacurrentplace: false,
    warndatacontactname: false,
    warndatacontactphone: false,
    warndatapmsname: false,
    warndatacmname: false,
    initPlace: false,
    clickFocus: false,
    warndatacontactphonemore: false,
    warndatacontactphonereserve: false,
    warndatacontactphonereservemore: false,
  }),
  computed: {
    google: gmapApi,
  },
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    if (
      (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
      self.userToken
    ) {
      localStorage.setItem("Token", self.userToken);
    }
    if (self.userToken && self.propertyID) {
      self.initAccName();
      self.changeLanguageData();
    } else {
      self.gotosignin();
    }
    self.defaultLG =
      localStorage?.getItem("ipcountry")?.toUpperCase() == "EN"
        ? "GB"
        : localStorage?.getItem("ipcountry")?.toUpperCase() || "TH";
    self.phoneNumberOptions = {
      placeholder: self.$t("SignupStep1.place_address_phone"),
      type: "number",
      required: true,
    };
    self.reservePhoneOptions = {
      placeholder: self.$t("SignupStep1.place_address_phone"),
      type: "number",
      required: true,
    };
    localStorage.setItem("signupstep", self.stepwh_signup);
    localStorage.setItem("SignupStep", self.stepwh_signup);
    self.markers = [
      {
        position: {
          lat: 44.2899,
          lng: 11.8774,
        },
      },
    ];
    self.center = {
      lat: 44.2899,
      lng: 11.8774,
    };
    self.itemsStep.map((el) => {
      if (self.stepwh_signup === el.step) {
        el.active = true;
      } else el.active = false;
    });
  },
  mounted() {
    EventBus.$on("sendStepItems", this.getStepData);
    EventBus.$on("refreshRightPhoto", this.changeLanguageData);
    this.colorHover();
  },
  watch: {
    "accommodationData.name"() {
      const self = this;
      self.checkCanSaveFunc();
    },
    "accommodationData.star"() {
      const self = this;
      self.checkCanSaveFunc();
    },
    "accommodationData.location1"() {
      const self = this;
      self.checkCanSaveFunc();
    },
    "accommodationData.location7"() {
      const self = this;
      self.checkCanSaveFunc();
    },
    "accommodationData.latlng_position"() {
      // console.log(this.accommodationData)
    },
    "accommodationData.radios_PMSOrCM"() {
      // console.log('radios_PMSOrCM: ', this.accommodationData.radios_PMSOrCM)
      const self = this;
      self.checkCanSaveFunc();
    },
    "accommodationData.location5"() {
      const self = this;
      self.selectProvinceFunc();
      self.checkHaveData();
      self.checkCanSaveFunc();
    },
    "accommodationData.location3"() {
      const self = this;
      self.selectAmphoeFunc();
      self.checkHaveData();
      self.checkCanSaveFunc();
    },
    "accommodationData.location4"() {
      const self = this;
      self.selectLocation6Func();
      self.checkHaveData();
      self.checkCanSaveFunc();
    },
    "accommodationData.location6"() {
      const self = this;
      self.checkCanSaveFunc();
    },
    currentPlace() {
      const self = this;
      self.checkCanSaveFunc();
    },
    "accommodationData.contactname"() {
      const self = this;
      self.checkCanSaveFunc();
    },
    "accommodationData.contactphone"() {
      const self = this;
      self.checkCanSaveFunc();
    },
    "accommodationData.contactreservephone"() {
      const self = this;
      self.checkCanSaveFunc();
    },
    "accommodationData.PMSName"() {
      const self = this;
      self.checkCanSaveFunc();
    },
    "accommodationData.CMName"() {
      const self = this;
      self.checkCanSaveFunc();
    },
    cansaveArea() {
      const self = this;
      if (self.cansaveArea) {
        self.mapOptions = {
          draggable: true,
          zoomControl: true,
          mapTypeControl: true,
          panControl: true,
          scaleControl: true,
          streetViewControl: true,
          rotateControl: true,
          fullscreenControl: true,
          disableDefaultUI: false,
        };
      } else {
        self.mapOptions = {
          fullscreenControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          disableDefaultUi: false,
          clickableIcons: false,
          draggable: false,
          zoomControl: false,
          scrollwheel: false,
          disableDoubleClickZoom: true,
        };
      }
    },
  },
  updated() {
    // this.$nextTick(() => this.scrollToTop());
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    numberHandler(val) {
      const validatedValue = val.replace(/[^0-9]/g, "");
      if (validatedValue === "" && val !== "") {
        alert("you must enter number only");
        return;
      }
      // console.log(validatedValue)
    },
    forceRerender() {
      this.componentKey += 1;
    },
    scrollToTop() {
      const self = this;
      var content = self.$refs.basicContainer;
      content.scrollTop = 0;
      window.scrollTo(0, 0);
    },
    changeLanguageData() {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.initStepData();
      self.initAllPropertyData();
      self.initCountry().then(() => EventBus.$emit("endloading"));
    },
    async initStepData() {
      const self = this;
      var temp = {};
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
          `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId
          }`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0];
          self.keepUserID = res?.data?.result[0]?.user?._id;
          if (res?.data?.result?.length) {
            self.itemsStep = [
              {
                name: self.$t("SignupStep.step1"),
                active: (self.stepwh_signup == 1 && true) || false,
                finish: temp?.step_signup?.step1 || false,
                linkItems: {
                  link: `signup/basic/${self.userToken}/${self.propertyID}`,
                  name: "SignupBasicInformationScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 1,
              },
              {
                name: self.$t("SignupStep.step2"),
                active: (self.stepwh_signup == 2 && true) || false,
                finish: temp?.step_signup?.step2 || false,
                linkItems: {
                  link: `signup/room/${self.userToken}/${self.propertyID}`,
                  name: "SignupRoomScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 2,
              },
              {
                name: self.$t("SignupStep.step3"),
                active: (self.stepwh_signup == 3 && true) || false,
                finish: temp?.step_signup?.step3 || false,
                linkItems: {
                  link: `signup/rateplan/${self.userToken}/${self.propertyID}`,
                  name: "SignupRatePlanScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 3,
              },
              {
                name: self.$t("SignupStep.step4"),
                active: (self.stepwh_signup == 4 && true) || false,
                finish: temp?.step_signup?.step4 || false,
                linkItems: {
                  link: `signup/facilities/${self.userToken}/${self.propertyID}`,
                  name: "SignupFacilitiesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 4,
              },
              {
                name: self.$t("SignupStep.step5"),
                active: (self.stepwh_signup == 5 && true) || false,
                finish: temp?.step_signup?.step5 || false,
                linkItems: {
                  link: `signup/pictures/${self.userToken}/${self.propertyID}`,
                  name: "SignupPicturesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 5,
              },
              {
                name: self.$t("SignupStep.step6"),
                active: (self.stepwh_signup == 6 && true) || false,
                finish: temp?.step_signup?.step6 || false,
                linkItems: {
                  link: `signup/policy/${self.userToken}/${self.propertyID}`,
                  name: "SignupPolicyScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 6,
              },
              {
                name: self.$t("SignupStep.step7"),
                active: (self.stepwh_signup == 7 && true) || false,
                finish: temp?.step_signup?.step7 || false,
                linkItems: {
                  link: `signup/payment/${self.userToken}/${self.propertyID}`,
                  name: "SignupPaymentScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 7,
              },
            ];
          } else self.gotosignin();
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        self.gotosignin();
      } finally {
        self.getStepData(self.itemsStep);
      }
    },
    gotosignin() {
      const item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
      self.itemsStar = [
        {
          _id: 0,
          name: self.$t("SignupStep1.star5"),
          value: 5,
        },
        {
          _id: 1,
          name: self.$t("SignupStep1.star4"),
          value: 4,
        },
        {
          _id: 2,
          name: self.$t("SignupStep1.star3"),
          value: 3,
        },
        {
          _id: 3,
          name: self.$t("SignupStep1.star2"),
          value: 2,
        },
        {
          _id: 4,
          name: self.$t("SignupStep1.star1"),
          value: 1,
        },
      ];
    },
    changePhotoRight() {
      const self = this;
      var tempLG = localStorage.getItem("language") === "th" ? "th" : "en";
      self.pathrightphoto = `${process.env.VUE_APP_API}/static/src/step1-${tempLG}.gif`;
    },
    async initAccName() {
      const self = this;
      var temp = [];
      var tempLatLng = "";
      self.itemsAccName = [];
      self.loadingZoho = true;
      // EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property/zoho_name`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            res?.data?.result?.map((el) => {
              if (el.Property_name && el.Property_name !== null) {
                if (el.Location) tempLatLng = el.Location.split(", ");
                temp.push({
                  _id: el.id,
                  name: el?.Property_name || "",
                  star: el?.Hotel_Star || "",
                  location1: el?.Address || "",
                  location2: el?.Address2 || "",
                  location3: el?.District || "",
                  location4: el?.City || "",
                  location5: el?.Province || "",
                  location6: Number(el?.Zip_Code) || "",
                  location7: el?.Country || "",
                  latlng: {
                    lat: Number(tempLatLng[0]) || 44.2899,
                    lng: Number(tempLatLng[1]) || 11.8774,
                  },
                });
              }
            });
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsAccName = temp;
        self.loadingZoho = false;
        // EventBus.$emit("endloading");
      }
    },
    selectAccNameFunc(item) {
      const self = this;
      var temp = {};
      temp = item;
      const tempCountry = self.itemsCountry.find(
        (p) =>
          p.name == temp.location7 ||
          temp.location7 == "ไทย" ||
          temp.location7 == "Thailand"
      );
      const tempProvince = self.itemsProvince.find(
        (p) =>
          p?.province == temp?.location5 &&
          p?.amphoe == temp?.location3 &&
          p?.district == temp?.location4
      );
      self.loadingCountry = true;
      self.loadingProvince = true;
      self.loadingAmphoe = true;
      self.loadingDistrict = true;
      self.loadingZipCode = true;

      // self.accommodationData.name = temp.name
      // self.accommodationData.star = temp.star
      self.accommodationData.location1 = temp.location1;
      self.accommodationData.location2 = temp.location2;
      self.accommodationData.location3 =
        tempProvince?.amphoe_code || temp.location3;
      self.accommodationData.location4 =
        tempProvince?.district_code || temp.location4;
      self.accommodationData.location5 =
        tempProvince?.province_code || temp.location5;
      self.accommodationData.location6 = temp.location6;
      self.accommodationData.location7 = tempCountry?._id || temp.location7;
      self.accommodationData.latlng_position = {
        lat: temp.latlng.lat,
        lng: temp.latlng.lng,
      };
      self.markers = [
        {
          position: {
            lat: temp.latlng.lat,
            lng: temp.latlng.lng,
          },
        },
      ];
      self.loadingCountry = false;
      self.loadingProvince = false;
      self.loadingAmphoe = false;
      self.loadingDistrict = false;
      self.loadingZipCode = false;
      self.center = { lat: temp.latlng.lat, lng: temp.latlng.lng };
    },
    async initCountry() {
      const self = this;
      var tempCountry = [];
      var temp = [];
      self.loadingCountry = true;
      self.loadingProvince = true;
      self.itemsCountry = [];
      self.itemsProvince = [];
      self.itemsAmphoe = [];
      self.itemsDistrict = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/setting/country`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            res?.data?.result?.map((el) => {
              tempCountry.push({
                _id: el._id || 0,
                name: el.value || "ไทย",
                value: el._id || "th",
                province: el.province || [],
              });
              if (el?.province?.length) {
                el?.province?.map((ele) => {
                  temp.push({
                    district: ele?.subdistrict || "",
                    amphoe: ele?.district || "",
                    province: ele?.province || "",
                    zipcode: ele?.zipcode || "",
                    district_code: ele?.subdistrict_code || "",
                    amphoe_code: ele?.district_code || "",
                    province_code: ele?.province_code || "",
                  });
                });
              }
            });
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.loadingCountry = false;
        self.loadingProvince = false;
        self.itemsCountry = tempCountry;
        self.itemsProvince = temp;
        self.checkHaveData();
        self.checkValueCountry();
      }
    },
    selectProvinceFunc() {
      const self = this;
      self.loadingAmphoe = true;
      var temp = [];
      self?.itemsProvince?.map((el) => {
        if (
          el?.province_code === self?.accommodationData?.location5 &&
          self.accommodationData.location5 &&
          self.accommodationData.location5 !== undefined
        ) {
          temp.push(el);
        }
      });
      self.loadingAmphoe = false;
      self.itemsAmphoe = temp;
    },
    selectAmphoeFunc() {
      const self = this;
      self.loadingDistrict = true;
      var temp = [];
      self?.itemsAmphoe?.map((el) => {
        if (
          el.province_code === self.accommodationData.location5 &&
          el.amphoe_code === self.accommodationData.location3 &&
          self.accommodationData.location5 &&
          self.accommodationData.location3
        ) {
          temp.push(el);
        }
      });
      self.loadingDistrict = false;
      self.itemsDistrict = temp;
    },
    selectLocation6Func() {
      const self = this;
      self.loadingZipCode = true;
      var temp = {};
      self?.itemsProvince?.map((el) => {
        if (
          el.province_code === self.accommodationData.location5 &&
          el.amphoe_code === self.accommodationData.location3 &&
          el.district_code === self.accommodationData.location4 &&
          self.accommodationData.location5 &&
          self.accommodationData.location3 &&
          self.accommodationData.location4
        ) {
          temp = el;
        }
      });
      self.loadingZipCode = false;
      if (temp.zipcode) self.accommodationData.location6 = temp.zipcode;
    },
    checkHaveData() {
      const self = this;
      if (self?.itemsStep[0]?.finish) {
        const tempCountry = self?.itemsCountry?.find(
          (element) => element.name === self.accommodationData.location7
        );
        if (tempCountry?.name)
          self.accommodationData.location7 = tempCountry?.value;
        if (tempCountry?.province?.length) {
          const tempDistrict = tempCountry?.province?.find(
            (element) =>
              element.province === self.accommodationData.location5 &&
              element.district === self.accommodationData.location3 &&
              element.subdistrict === self.accommodationData.location4
          );
          if (tempDistrict?.province)
            self.accommodationData.location5 = tempDistrict?.province_code;
          if (tempDistrict?.district)
            self.accommodationData.location3 = tempDistrict?.district_code;
          if (tempDistrict?.subdistrict)
            self.accommodationData.location4 = tempDistrict?.subdistrict_code;
          if (tempDistrict?.zipcode)
            self.accommodationData.location6 = tempDistrict?.zipcode;
        }
      }
    },
    changePMS(val) {
      const self = this;
      self.accommodationData.radios_PMSOrCM = val;
      if (val == "not") {
        self.accommodationData.cb_havePMS = false;
        self.accommodationData.PMSName = null;
        self.accommodationData.cb_haveCM = false;
        self.accommodationData.CMName = null;
      }
      self.componentKey = 0;
    },
    changePMSName(value) {
      const self = this;
      self.accommodationData.cb_havePMS = !value;
      if (value) self.accommodationData.PMSName = "";
      self.forceRerender();
      self.checkCanSaveFunc();
    },
    changeCMName(value) {
      const self = this;
      self.accommodationData.cb_haveCM = !value;
      if (value) self.accommodationData.CMName = "";
      self.forceRerender();
      self.checkCanSaveFunc();
    },
    async initAllPropertyData() {
      const self = this;
      var temp = {};
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
          `/property?_id=${self.propertyID || self.$route.params.propertyId}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          temp = res?.data?.result[0];
          if (res?.data?.total) {
            if (temp?.step_signup?.step1) self.initPlace = true;
            self.accommodationData = {
              name: temp?.name || "",
              star: temp?.star || "",
              location1: temp?.contact?.address_line_1 || "",
              location2: temp?.contact?.address_line_2 || "",
              location3: temp?.contact?.district || "",
              location4: temp?.contact?.subdistrict || "",
              location5: temp?.contact?.province || "",
              location6: temp?.contact?.post_code || "",
              location7: temp?.contact?.country || "",
              latlng_position: temp?.contact?.location,
              contactname: temp?.contact?.name || "",
              contactphone: temp?.contact?.phones[0]?.phone_number || "",
              contactreservephone: temp?.contact?.phones[1]?.phone_number || "",
              radios_PMSOrCM:
                temp?.channel_manager || temp?.pms ? "need" : "not",
              cb_havePMS: temp?.pms ? true : false,
              PMSName: temp?.pms,
              cb_haveCM: temp?.channel_manager ? true : false,
              CMName: temp?.channel_manager,
            };
            self.pinPhone1 =
              temp?.contact?.phones[0]?.country_code || self.defaultLG || "th";
            self.pinPhone2 =
              temp?.contact?.phones[1]?.country_code || self.defaultLG || "th";
            self.markers = [
              {
                position: {
                  lat: temp?.contact?.location?.latitude || 44.2899,
                  lng: temp?.contact?.location?.longitude || 11.8774,
                },
              },
            ];
            self.center = {
              lat: temp?.contact?.location?.latitude || 44.2899,
              lng: temp?.contact?.location?.longitude || 11.8774,
            };
            self.cansaveArea = temp?.contact?.location?.latitude ? false : true;
            if (temp?.step_signup?.step1 && self.markers?.length)
              self.getAddressPlace();
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        self.checkHaveData();
        self.checkValueCountry();
        self.$refs.location1.focus();
      }
    },
    checkValueCountry() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const tempCountry = self?.itemsCountry?.find(
        (p) => p.name == self.accommodationData.location7
      );
      if (tempCountry?.value) {
        self.accommodationData.location7 = tempCountry?.value || "";
      }
      const tempProvince = self?.itemsProvince?.find(
        (p) =>
          p.province == self.accommodationData.location5 &&
          p.amphoe == self.accommodationData.location3 &&
          p.district == self.accommodationData.location4
      );
      if (
        tempProvince?.province_code &&
        tempProvince?.amphoe_code &&
        tempProvince?.district_code
      ) {
        self.accommodationData.location5 = tempProvince?.province_code || "";
        self.accommodationData.location3 = tempProvince?.amphoe_code || "";
        self.accommodationData.location4 = tempProvince?.district_code || "";
      }
      EventBus.$emit("endloading");
    },
    selectarea({ latLng }) {
      if (this.cansaveArea) {
        this.markers = [];
        this.markers = [{ position: { lat: latLng.lat(), lng: latLng.lng() } }];
        this.newlat = this.markers[0].position.lat;
        this.newlng = this.markers[0].position.lng;
        this.initPlace = false;

        this.getAddressPlace();
      }
    },
    async getAddressPlace() {
      const self = this;
      var locationArea = "";
      try {
        const res = await self.axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${self?.newlat || self?.markers[0]?.position?.lat
          },${self?.newlng || self?.markers[0]?.position?.lng
          }&key=${PlaceApiKey}`
        );
        if (res?.status == 200)
          locationArea = res?.data?.results[0]?.formatted_address || "";
      } catch (error) {
        console.warn(error);
      } finally {
        if (locationArea) {
          self.currentPlace = null;
          self.currentPlaceName = locationArea;
          if (locationArea)
            self.places.push({ formatted_address: self.locationArea });
        }
        self.checkWarnFillDataFunc();
        // console.log("current: ", self.currentPlace, locationArea);
      }
      return locationArea;
    },
    setPlace(place) {
      const self = this;
      if (place) {
        self.currentPlace = place;
        self.currentPlaceName = null;
        self.initPlace = false;
      }
    },
    searchArea() {
      const self = this;
      if (self.currentPlace) {
        self.markers = [];
        const marker = {
          lat: self.currentPlace.geometry.location.lat(),
          lng: self.currentPlace.geometry.location.lng(),
        };
        self.markers.push({ position: marker });
        self.center = marker;
        self.places.push(self.currentPlace);
        self.currentPlace = null;
        self.currentPlaceName = null;
        self.initPlace = false;
        self.cansaveArea = true;
      }
    },
    saveArea() {
      const self = this;
      if (self?.markers?.length) {
        self.accommodationData.latlng_position = {
          latitude:
            self?.markers?.position?.lat ||
            self?.markers[0]?.position?.lat ||
            44.2899,
          longitude:
            self?.markers?.position?.lng ||
            self?.markers[0]?.position?.lng ||
            11.8774,
        };
        self.cansaveArea = false;
      }
    },
    cancelArea() {
      const self = this;
      if (
        self?.accommodationData?.latlng_position?.lat &&
        self?.accommodationData?.latlng_position?.lng
      ) {
        self.markers = [];
        const marker = {
          lat: self.accommodationData.latlng_position.lat,
          lng: self.accommodationData.latlng_position.lng,
        };
        self.markers.push({ position: marker });
        self.center = marker;
        self.currentPlace = null;
        self.cansaveArea = true;
      }
    },
    editArea() {
      const self = this;
      self.cansaveArea = true;
      self.clickFocus = false;
    },
    changeTelCT1(obj) {
      const self = this;
      self.dialCode1 = obj.dialCode;
      self.pinPhone1 = obj.iso2 || "TH";
      self.checkWarnFillDataFunc();
    },
    changeTelCT2(obj) {
      const self = this;
      self.dialCode2 = obj.dialCode;
      self.pinPhone2 = obj.iso2 || "TH";
      self.checkWarnFillDataFunc();
    },
    async basicDataFunc(num) {
      const self = this;
      const tempPhone1 = self?.accommodationData?.contactphone?.replaceAll(
        " ",
        ""
      );
      const tmpPhone1 = `+${tempPhone1}`;
      const tempPhone2 = `${tempPhone1[1]}${tempPhone1[2]}`;
      const tempPhone3 = `${tempPhone1[0]}`;
      var tmpPhone2,
        tmpPhone3,
        tmpPhone = "";

      if (tempPhone2 == self.dialCode1) {
        tmpPhone2 = tempPhone1?.replace(`+${tempPhone2}`, "");
      } else if (tempPhone3 == "0")
        tmpPhone3 = tmpPhone1?.replace(`+${tempPhone3}`, "");
      tmpPhone = tmpPhone2 || tmpPhone3 || tempPhone1;

      const tempRePhone1 = self?.accommodationData?.contactreservephone?.replaceAll(
        " ",
        ""
      );
      const tmpRePhone1 = `+${tempRePhone1}`;
      const tempRePhone2 = `${tempRePhone1[1]}${tempRePhone1[2]}`;
      const tempRePhone3 = `${tempRePhone1[0]}`;
      var tmpRePhone2,
        tmpRePhone3,
        tmpRePhone = "";

      if (tempRePhone2 == self.dialCode2) {
        tmpRePhone2 = tempRePhone1?.replace(`+${tempRePhone2}`, "");
      } else if (tempRePhone3 == "0")
        tmpRePhone3 = tmpRePhone1?.replace(`+${tempRePhone3}`, "");
      tmpRePhone = tmpRePhone2 || tmpRePhone3 || tempRePhone1;

      if (
        self.accommodationData.name &&
        self.accommodationData.star &&
        self.accommodationData.location1 &&
        // self.accommodationData.location2 &&
        self.accommodationData.location3 &&
        self.accommodationData.location4 &&
        self.accommodationData.location5 &&
        self.accommodationData.location6 &&
        self.accommodationData.location7 &&
        self.accommodationData.latlng_position &&
        self.accommodationData.contactname &&
        self.accommodationData.contactphone &&
        tmpPhone.length >= 8 &&
        self.accommodationData.contactreservephone &&
        tmpRePhone.length >= 8 &&
        self.accommodationData.radios_PMSOrCM
      ) {
        if (self.accommodationData.radios_PMSOrCM === "need") {
          if (
            (self.accommodationData.cb_havePMS &&
              !self.accommodationData.PMSName) ||
            (self.accommodationData.cb_haveCM && !self.accommodationData.CMName)
          ) {
            swal(
              self.$t("Alert.warn_title"),
              `${self.$t("Alert.pleasefill")}${self.accommodationData.cb_havePMS &&
                !self.accommodationData.PMSName
                ? ` ${self.$t("SignupStep1.pms_title")}`
                : ""
              }${self.accommodationData.cb_haveCM &&
                !self.accommodationData.CMName
                ? ` ${self.$t("SignupStep1.cm_title")}`
                : ""
              }`,
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          } else {
            self.updateBasicData(num);
          }
        } else {
          self.updateBasicData(num);
        }
      } else {
        if (
          !self.accommodationData.name ||
          self.accommodationData.name == "" ||
          !self.accommodationData.star ||
          self.accommodationData.star == "" ||
          !self.accommodationData.location1 ||
          self.accommodationData.location1 == "" ||
          !self.accommodationData.location3 ||
          self.accommodationData.location3 == "" ||
          !self.accommodationData.location4 ||
          self.accommodationData.location4 == "" ||
          !self.accommodationData.location5 ||
          self.accommodationData.location5 == "" ||
          !self.accommodationData.location6 ||
          self.accommodationData.location6 == "" ||
          !self.accommodationData.location7 ||
          self.accommodationData.location7 == "" ||
          !self.accommodationData.latlng_position ||
          !self.accommodationData.contactname ||
          self.accommodationData.contactname == "" ||
          !self.accommodationData.contactphone ||
          self.accommodationData.contactphone == "" ||
          tmpPhone?.length < 8 ||
          !self.accommodationData.contactreservephone ||
          self.accommodationData.contactreservephone == "" ||
          tmpRePhone?.length < 8 ||
          !self.accommodationData.radios_PMSOrCM ||
          self.accommodationData.radios_PMSOrCM == ""
        ) {
          swal(
            self.$t("Alert.warn_title"),
            `${self.$t("Alert.pleasefill")}${!self.accommodationData.name || self.accommodationData.name == ""
              ? ` ${self.$t("SignupStep1.fillpropertyname")}`
              : ""
            }${!self.accommodationData.star || self.accommodationData.star == ""
              ? ` ${self.$t("SignupStep1.star")}`
              : ""
            }${!self.accommodationData.location1 ||
              self.accommodationData.location1 == ""
              ? ` ${self.$t("SignupStep1.location1")}`
              : ""
            }${!self.accommodationData.location3 ||
              self.accommodationData.location3 == ""
              ? ` ${self.$t("SignupStep1.location3")}`
              : ""
            }${!self.accommodationData.location4 ||
              self.accommodationData.location4 == ""
              ? ` ${self.$t("SignupStep1.location4")}`
              : ""
            }${!self.accommodationData.location5 ||
              self.accommodationData.location5 == ""
              ? ` ${self.$t("SignupStep1.location5")}`
              : ""
            }${!self.accommodationData.location6 ||
              self.accommodationData.location6 == ""
              ? ` ${self.$t("SignupStep1.location6")}`
              : ""
            }${!self.accommodationData.location7 ||
              self.accommodationData.location7 == ""
              ? ` ${self.$t("SignupStep1.location7")}`
              : ""
            }${!self.accommodationData.latlng_position
              ? ` ${self.$t("SignupStep1.select_address")}`
              : ""
            }${!self.accommodationData.contactname ||
              self.accommodationData.contactname == ""
              ? ` ${self.$t("SignupStep1.address_contact_name")}`
              : ""
            }${!self.accommodationData.contactphone ||
              self.accommodationData.contactphone == ""
              ? ` ${self.$t("SignupStep1.address_phone")}`
              : ""
            }${tmpPhone.length < 8
              ? ` ${self.$t("SignupStep1.address_phone_more")}`
              : ""
            }${!self.accommodationData.contactreservephone ||
              self.accommodationData.contactreservephone == ""
              ? ` ${self.$t("SignupStep1.address_reserve_phone")}`
              : ""
            }${tmpRePhone.length < 8
              ? ` ${self.$t("SignupStep1.address_reserve_phone_more")}`
              : ""
            }${!self.accommodationData.radios_PMSOrCM ||
              self.accommodationData.radios_PMSOrCM == ""
              ? " PMS/CM"
              : ""
            }`,
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      }
    },
    async updateBasicData(num) {
      const self = this;
      var tempPhone = [];
      if (self?.accommodationData?.contactphone) {
        tempPhone.push({
          country_code: self?.pinPhone1 || self.defaultLG,
          phone_number: self?.accommodationData?.contactphone || "",
        });
      }
      if (self?.accommodationData?.contactreservephone) {
        tempPhone.push({
          country_code: self?.pinPhone2 || self.defaultLG,
          phone_number: self?.accommodationData?.contactreservephone || "",
        });
      }
      const obj = {
        name:
          typeof self.accommodationData.name === "object"
            ? self?.accommodationData?.name?.name || ""
            : self.accommodationData.name,
        star: self.accommodationData.star,
        contact: {
          name: self.accommodationData.contactname,
          address_line_1: self.accommodationData.location1,
          address_line_2: self.accommodationData.location2,
          subdistrict: self.accommodationData.location4.toString(),
          district: self.accommodationData.location3.toString(),
          province: self.accommodationData.location5.toString(),
          post_code: self.accommodationData.location6.toString(),
          country: self.accommodationData.location7,
          location: self.accommodationData.latlng_position,
          phones: tempPhone,
          pinphones: [self?.pinPhone1 || "TH", self?.pinPhone2 || "TH"],
        },
        pms: self.accommodationData.cb_havePMS
          ? self.accommodationData.PMSName
          : null,
        channel_manager: self.accommodationData.cb_haveCM
          ? self.accommodationData.CMName
          : null,
      };
      // console.log('obj: ', obj)
      self.updateandvalidFunc(obj);
    },
    gotoroom() {
      const self = this;
      var item = {
        link: `signup/room/${self.propertyID}`,
        name: "SignupRoomScreen",
        params: { propertyId: self.propertyID },
      };
      EventBus.$emit("changePathname", item);
    },
    changeStep(linkItems) {
      const self = this;
      // console.log(self.accommodationData)
      if (
        self.accommodationData.name &&
        self.accommodationData.star &&
        self.accommodationData.location1 &&
        // self.accommodationData.location2 &&
        self.accommodationData.location3 &&
        self.accommodationData.location4 &&
        self.accommodationData.location5 &&
        self.accommodationData.location6 &&
        self.accommodationData.location7 &&
        self.accommodationData.latlng_position &&
        self.accommodationData.contactname &&
        self.accommodationData.contactphone &&
        self.accommodationData.radios_PMSOrCM
      ) {
        if (self.accommodationData.radios_PMSOrCM === "need") {
          if (
            (self.accommodationData.cb_havePMS &&
              !self.accommodationData.PMSName) ||
            (self.accommodationData.cb_haveCM && !self.accommodationData.CMName)
          ) {
            swal(
              self.$t("Alert.warn_title"),
              `${self.$t("Alert.pleasefill")}${self.accommodationData.cb_havePMS &&
                !self.accommodationData.PMSName
                ? ` ${self.$t("SignupStep1.pms_title")}`
                : ""
              }${self.accommodationData.cb_haveCM &&
                !self.accommodationData.CMName
                ? ` ${self.$t("SignupStep1.cm_title")}`
                : ""
              }`,
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          } else {
            self.updateBasicDataChangeStep(linkItems);
          }
        } else {
          self.updateBasicDataChangeStep(linkItems);
        }
      } else {
        if (
          !self.accommodationData.name ||
          self.accommodationData.name == "" ||
          !self.accommodationData.star ||
          self.accommodationData.star == "" ||
          !self.accommodationData.location1 ||
          self.accommodationData.location1 == "" ||
          !self.accommodationData.location3 ||
          self.accommodationData.location3 == "" ||
          !self.accommodationData.location4 ||
          self.accommodationData.location4 == "" ||
          !self.accommodationData.location5 ||
          self.accommodationData.location5 == "" ||
          !self.accommodationData.location6 ||
          self.accommodationData.location6 == "" ||
          !self.accommodationData.location7 ||
          self.accommodationData.location7 == "" ||
          !self.accommodationData.latlng_position ||
          !self.accommodationData.contactname ||
          self.accommodationData.contactname == "" ||
          !self.accommodationData.contactphone ||
          self.accommodationData.contactphone == "" ||
          !self.accommodationData.radios_PMSOrCM ||
          self.accommodationData.radios_PMSOrCM == ""
        ) {
          swal(
            self.$t("Alert.warn_title"),
            `${self.$t("Alert.pleasefill")}${!self.accommodationData.name || self.accommodationData.name == ""
              ? ` ${self.$t("SignupStep1.fillpropertyname")}`
              : ""
            }${!self.accommodationData.star || self.accommodationData.star == ""
              ? ` ${self.$t("SignupStep1.star")}`
              : ""
            }${!self.accommodationData.location1 ||
              self.accommodationData.location1 == ""
              ? ` ${self.$t("SignupStep1.location1")}`
              : ""
            }${!self.accommodationData.location3 ||
              self.accommodationData.location3 == ""
              ? ` ${self.$t("SignupStep1.location3")}`
              : ""
            }${!self.accommodationData.location4 ||
              self.accommodationData.location4 == ""
              ? ` ${self.$t("SignupStep1.location4")}`
              : ""
            }${!self.accommodationData.location5 ||
              self.accommodationData.location5 == ""
              ? ` ${self.$t("SignupStep1.location5")}`
              : ""
            }${!self.accommodationData.location6 ||
              self.accommodationData.location6 == ""
              ? ` ${self.$t("SignupStep1.location6")}`
              : ""
            }${!self.accommodationData.location7 ||
              self.accommodationData.location7 == ""
              ? ` ${self.$t("SignupStep1.location7")}`
              : ""
            }${!self.accommodationData.latlng_position
              ? ` ${self.$t("SignupStep1.select_address")}`
              : ""
            }${!self.accommodationData.contactname ||
              self.accommodationData.contactname == ""
              ? ` ${self.$t("SignupStep1.address_contact_name")}`
              : ""
            }${!self.accommodationData.contactphone ||
              self.accommodationData.contactphone == ""
              ? ` ${self.$t("SignupStep1.address_phone")}`
              : ""
            }${!self.accommodationData.radios_PMSOrCM ||
              self.accommodationData.radios_PMSOrCM == ""
              ? " PMS/CM"
              : ""
            }`,
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      }
    },
    updateBasicDataChangeStep(linkItems) {
      const self = this;
      var tempPhone = [];
      if (self?.accommodationData?.contactphone) {
        tempPhone.push({
          country_code: self?.pinPhone1 || self.defaultLG || "TH",
          phone_number: self?.accommodationData?.contactphone || "",
        });
      }
      if (self?.accommodationData?.contactreservephone) {
        tempPhone.push({
          country_code: self?.pinPhone2 || self.defaultLG || "TH",
          phone_number: self?.accommodationData?.contactreservephone || "",
        });
      }
      const obj = {
        name:
          typeof self.accommodationData.name === "object"
            ? self?.accommodationData?.name?.name || ""
            : self.accommodationData.name,
        star: self.accommodationData.star,
        contact: {
          name: self.accommodationData.contactname,
          address_line_1: self.accommodationData.location1,
          address_line_2: self.accommodationData.location2,
          subdistrict: self.accommodationData.location4.toString(),
          district: self.accommodationData.location3.toString(),
          province: self.accommodationData.location5.toString(),
          post_code: self.accommodationData.location6.toString(),
          country: self.accommodationData.location7,
          location: self.accommodationData.latlng_position,
          phones: tempPhone,
          pinphones: [self?.pinPhone1 || "TH", self?.pinPhone2 || "TH"],
        },
        pms: self.accommodationData.cb_havePMS
          ? self.accommodationData.PMSName
          : null,
        channel_manager: self.accommodationData.cb_haveCM
          ? self.accommodationData.CMName
          : null,
      };
      // console.log(obj)
      self.updateandnotvalidFunc(obj, linkItems);
    },
    async updateandvalidFunc(obj) {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
          `/property/step1?property_id=${self.propertyID || self.$route.params.propertyId
          }&next=${1}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.gotoroom();
          EventBus.$emit("endloading");
        } else {
          EventBus.$emit("endloading");
          swal("Warning", "Please try again", "warning", {
            button: false,
            timer: 3000,
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    async updateandnotvalidFunc(obj, linkItems) {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
          `/property/step1?property_id=${self.propertyID || self.$route.params.propertyId
          }&next=${0}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("changePathname", linkItems);
          EventBus.$emit("endloading");
        } else {
          EventBus.$emit("endloading");
          swal("Warning", "Please try again", "warning", {
            button: false,
            timer: 3000,
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    checkCanSaveFunc() {
      const self = this;
      const tempPhone1 = self?.accommodationData?.contactphone?.replaceAll(
        " ",
        ""
      );
      const tmpPhone1 = `+${tempPhone1}`;
      const tempPhone2 = `${tempPhone1[1]}${tempPhone1[2]}`;
      const tempPhone3 = `${tempPhone1[0]}`;
      var tmpPhone2,
        tmpPhone3,
        tmpPhone = "";

      if (tempPhone2 == self.dialCode1) {
        tmpPhone2 = tempPhone1?.replace(`+${tempPhone2}`, "");
      } else if (tempPhone3 == "0")
        tmpPhone3 = tmpPhone1?.replace(`+${tempPhone3}`, "");
      tmpPhone = tmpPhone2 || tmpPhone3 || tempPhone1;

      // console.log('phone: ', tmpPhone, tmpPhone?.length)
      const tempRePhone1 = self?.accommodationData?.contactreservephone?.replaceAll(
        " ",
        ""
      );
      const tmpRePhone1 = `+${tempRePhone1}`;
      const tempRePhone2 = `${tempRePhone1[1]}${tempRePhone1[2]}`;
      const tempRePhone3 = `${tempRePhone1[0]}`;
      var tmpRePhone2,
        tmpRePhone3,
        tmpRePhone = "";

      if (tempRePhone2 == self.dialCode1) {
        tmpRePhone2 = tempRePhone1?.replace(`+${tempRePhone2}`, "");
      } else if (tempRePhone3 == "0")
        tmpRePhone3 = tmpRePhone1?.replace(`+${tempRePhone3}`, "");
      tmpRePhone = tmpRePhone2 || tmpRePhone3 || tempRePhone1;

      if (
        self?.accommodationData?.name ||
        self?.accommodationData?.star ||
        self?.accommodationData?.location1 ||
        self?.accommodationData?.location7 ||
        self?.accommodationData?.location5 ||
        self?.accommodationData?.location3 ||
        self?.accommodationData?.location4 ||
        self?.accommodationData?.location6 ||
        self?.places?.length ||
        self?.currentPlaceName ||
        self?.accommodationData?.contactname ||
        (self?.accommodationData?.contactphone && tmpPhone?.length < 8) ||
        (self?.accommodationData?.contactreservephone && tmpRePhone?.length < 8)
      ) {
        self.cansavebasic = true;
      } else {
        self.cansavebasic = false;
      }
      self.checkWarnFillDataFunc();
    },
    checkWarnFillDataFunc() {
      const self = this;
      var elementName = document.getElementById("selectaccname");
      var elementStar = document.getElementById("selectstar");
      var elementLocation1 = document.getElementById("location1");
      var elementLocation7 = document.getElementById("selectcountry");
      var elementLocation5 = document.getElementById("selectprovince");
      var elementLocation3 = document.getElementById("selectamphoe");
      var elementLocation4 = document.getElementById("selectdistrict");
      var elementLocation6 = document.getElementById("location6");
      var elementPlace = document.getElementById("setplacenearby");
      var elementContactName = document.getElementById("contactname");
      var elementContactPhone = document.getElementById("contactphone");
      var elementContactReservePhone = document.getElementById("contactreservephone");
      var elementPMSName = document.getElementById("pmsname");
      var elementCMName = document.getElementById("cmname");

      if (!self?.accommodationData?.name) {
        self.warndataname = true;
        elementName.classList.add("border-pink-focus");
      } else {
        self.warndataname = false;
        elementName?.classList?.remove("border-pink-focus");
      }
      // if (!self?.accommodationData?.star) {
      //   elementStar.classList.add("border-pink-focus");
      // } else {
      //   elementStar?.classList?.remove("border-pink-focus");
      // }
      if (!self?.accommodationData?.location1) {
        self.warndatalocation1 = true;
        elementLocation1.classList.add("border-pink-focus");
      } else {
        self.warndatalocation1 = false;
        elementLocation1?.classList?.remove("border-pink-focus");
      }
      // if (!self?.accommodationData?.location7) {
      //   elementLocation7.classList.add("border-pink-focus");
      // } else {
      //   elementLocation7?.classList?.remove("border-pink-focus");
      // }
      // if (!self?.accommodationData?.location5) {
      //   elementLocation5.classList.add("border-pink-focus");
      // } else {
      //   elementLocation5?.classList?.remove("border-pink-focus");
      // }
      // if (!self?.accommodationData?.location3) {
      //   elementLocation3.classList.add("border-pink-focus");
      // } else {
      //   elementLocation3?.classList?.remove("border-pink-focus");
      // }
      // if (!self?.accommodationData?.location4) {
      //   elementLocation4.classList.add("border-pink-focus");
      // } else {
      //   elementLocation4?.classList?.remove("border-pink-focus");
      // }
      if (!self?.accommodationData?.location6) {
        self.warndatazipcode = true;
        elementLocation6.classList.add("border-pink-focus");
      } else {
        self.warndatazipcode = false;
        elementLocation6?.classList?.remove("border-pink-focus");
      }
      if (
        (self.initPlace && !self?.currentPlaceName) ||
        (self.newlat && self.newlng && !self?.currentPlaceName)
      ) {
        self.warndatacurrentplace = true;
        elementPlace.classList.add("border-pink-focus");
      } else if (!self?.places?.length) {
        self.warndatacurrentplace = true;
        elementPlace.classList.add("border-pink-focus");
      } else {
        self.warndatacurrentplace = false;
        elementPlace?.classList?.remove("border-pink-focus");
      }
      if (!self?.accommodationData?.contactname) {
        self.warndatacontactname = true;
        elementContactName.classList.add("border-pink-focus");
      } else {
        self.warndatacontactname = false;
        elementContactName?.classList?.remove("border-pink-focus");
      }

      const tempPhone1 = self?.accommodationData?.contactphone?.replaceAll(
        " ",
        ""
      );
      const tmpPhone1 = `+${tempPhone1}`;
      const tempPhone2 = `${tempPhone1[1]}${tempPhone1[2]}`;
      const tempPhone3 = `${tempPhone1[0]}`;
      var tmpPhone2,
        tmpPhone3,
        tmpPhone = "";

      if (tempPhone2 == self.dialCode1) {
        tmpPhone2 = tempPhone1?.replace(`+${tempPhone2}`, "");
      } else if (tempPhone3 == "0")
        tmpPhone3 = tmpPhone1?.replace(`+${tempPhone3}`, "");
      tmpPhone = tmpPhone2 || tmpPhone3 || tempPhone1;
      if (!self?.accommodationData?.contactphone && tmpPhone?.length < 8) {
        self.warndatacontactphonemore = true;
        self.warndatacontactphone = true;
        elementContactPhone.classList.add("border-pink-focus");
      } else if (
        self?.accommodationData?.contactphone &&
        tmpPhone?.length < 8
      ) {
        self.warndatacontactphonemore = true;
        self.warndatacontactphone = false;
        elementContactPhone.classList.add("border-pink-focus");
      } else {
        self.warndatacontactphonemore = false;
        self.warndatacontactphone = false;
        elementContactPhone?.classList?.remove("border-pink-focus");
      }

      // console.log(self?.accommodationData?.contactphone, tmpPhone?.length)

      const tempRePhone1 = self?.accommodationData?.contactreservephone?.replaceAll(
        " ",
        ""
      );
      const tmpRePhone1 = `+${tempRePhone1}`;
      const tempRePhone2 = `${tempRePhone1[1]}${tempRePhone1[2]}`;
      const tempRePhone3 = `${tempRePhone1[0]}`;
      var tmpRePhone2,
        tmpRePhone3,
        tmpRePhone = "";

      if (tempRePhone2 == self.dialCode1) {
        tmpRePhone2 = tempRePhone1?.replace(`+${tempRePhone2}`, "");
      } else if (tempRePhone3 == "0")
        tmpRePhone3 = tmpRePhone1?.replace(`+${tempRePhone3}`, "");
      tmpRePhone = tmpRePhone2 || tmpRePhone3 || tempRePhone1;

      if (!self?.accommodationData?.contactreservephone && tmpRePhone?.length < 8) {
        self.warndatacontactreservephonemore = true;
        self.warndatacontactreservephone = true;
        elementContactReservePhone.classList.add("border-pink-focus");
      } else if (
        self?.accommodationData?.contactreservephone &&
        tmpRePhone?.length < 8
      ) {
        self.warndatacontactreservephonemore = true;
        self.warndatacontactreservephone = false;
        elementContactReservePhone.classList.add("border-pink-focus");
      } else {
        self.warndatacontactreservephonemore = false;
        self.warndatacontactreservephone = false;
        elementContactReservePhone?.classList?.remove("border-pink-focus");
      }

      if (
        self?.accommodationData?.cb_havePMS &&
        !self?.accommodationData?.PMSName
      ) {
        self.warndatapmsname = true;
        elementPMSName.classList.add("border-pink-focus");
      } else {
        self.warndatapmsname = false;
        elementPMSName?.classList?.remove("border-pink-focus");
      }
      if (
        self?.accommodationData?.cb_haveCM &&
        !self?.accommodationData?.CMName
      ) {
        self.warndatacmname = true;
        elementCMName.classList.add("border-pink-focus");
      } else {
        self.warndatacmname = false;
        elementCMName?.classList?.remove("border-pink-focus");
      }
    },
    focusChangePlace() {
      const self = this;
      self.clickFocus = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/bootstrap/jquery.scrollbar.css";

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  border-color: var(--pink) !important;
}

.btn.btn-searchmap {
  background-color: var(--pink) !important;
  border-color: var(--pink) !important;
}

.btn.btn-searchmap:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn-cancel-searchmap:hover {
  background-color: #777 !important;
  border-color: #777 !important;
}

.btn[disabled].btn-searchmap {
  border-color: #d4eaea !important;
  background-color: #d4eaea !important;
  min-height: 2.625rem !important;
}

.btn-cancel-searchmap:hover span {
  color: #fff !important;
}
</style>

<style>
.vti__dropdown-list {
  z-index: 999 !important;
  background-color: #fff !important;
}

.vti__dropdown {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -ms-flex-line-pack: center !important;
  align-content: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  position: relative !important;
  padding: 0 7px !important;
  cursor: pointer !important;
}

.vti__dropdown:hover {
  background-color: transparent !important;
}

.vti__dropdown.open {
  background-color: transparent !important;
}

.vti__input {
  border: none !important;
  border-radius: 0 2px 2px 0 !important;
  width: 100% !important;
  outline: none !important;
  padding-left: 7px !important;
}

.vue-tel-input.disabled .dropdown,
.vue-tel-input.disabled .selection,
.vue-tel-input.disabled input {
  cursor: no-drop !important;
}

.vue-tel-input:focus-within {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #e83e8c !important;
}

.vue-tel-input {
  display: flex !important;
  align-items: center !important;
  justify-content: left !important;
}

.border-pink-focus {
  border-color: var(--pink) !important;
}

.title-green {
  color: var(--green) !important;
}

.title-gray {
  color: var(--gray) !important;
}

.navtop-canclick {
  cursor: pointer !important;
}

.btn[disabled] {
  height: fit-content !important;
}
</style>

<style>
@media (min-width: 768px) and (max-width: 1024px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 7.5rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}

@media (min-width: 321px) and (max-width: 767px) {
  .siq_bR {
    width: 4.5rem !important;
    height: 3rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 4.5rem !important;
    height: 3rem !important;
    object-fit: contain !important;
  }
}

@media (max-width: 320px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
</style>
